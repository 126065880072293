$(function() {
  "use strict";

  var data_url = ($('#originalurl').data('url'));
  var btn_prompt = document.querySelector(".btn-prompt")
  if(btn_prompt){
    btn_prompt.addEventListener("click", () => {
      var doc = prompt("Copy to clipboard: Ctrl+C", data_url);
    });
  }

  // shortener link
  $(".link").click(function(){
    var link = $(this).data("link");
    var person = prompt("Copy to clipboard: Ctrl+C", link)
  })

  // show modal in product with shortener link
  window.onload = function(){
    document.querySelectorAll('#show_modal_product').forEach((elem) => {
      elem.click()
    })
  }

  $(".preloader").delay(150).fadeOut();
  $("body, .page-wrapper").trigger("resize");
  $(".page-wrapper").delay(30).show();
  // ==============================================================
  // Theme options
  // ==============================================================
  // ==============================================================
  // sidebar-hover
  // ==============================================================

  $(".left-sidebar").hover(
    function() {
      $(".navbar-header").addClass("expand-logo");
    },

    function() {
      $(".navbar-header").removeClass("expand-logo");
    }
  );

  // this is for close icon when navigation open in mobile view
  $(".nav-toggler").on('click', function() {
    $("#main-wrapper").toggleClass("show-sidebar");
    $(".nav-toggler i").toggleClass("zmdi-menu");
    $('.user-sidenav-mobile-toggler').removeClass('show');
    $('.user-sidebar').removeClass('show');
  });

  $(".nav-lock").on('click', function() {
    $("body").toggleClass("lock-nav");
    $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
    $("body, .page-wrapper").trigger("resize");
  });

  $(".search-box a, .search-box .app-search .srh-btn").on('click', function() {
    $(".app-search").toggle(200);
    $(".app-search input").focus();
  });

  // ==============================================================
  // Right sidebar options
  // ==============================================================
  $(function() {
    $(".service-panel-toggle").on('click', function() {
      $(".customizer").toggleClass('show-service-panel');
    });

    $('.page-wrapper').on('click', function() {
      $(".customizer").removeClass('show-service-panel');
    });
  });

  // ==============================================================
  // This is for the floating labels
  // ==============================================================
  $('.floating-labels .form-control').on('focus blur', function(e) {
    $(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
  }).trigger('blur');

  // ==============================================================
  //tooltip
  // ==============================================================
  $(function() {
    $('[data-toggle="tooltip"]').tooltip()
  })
  // ==============================================================
  //Popover
  // ==============================================================
  $(function() {
    $('[data-toggle="popover"]').popover()
  })

  // ==============================================================
  // Perfact scrollbar
  // ==============================================================
  $('.message-center, .customizer-body, .scrollable').perfectScrollbar({
    wheelPropagation: !0
  });

  /*var ps = new PerfectScrollbar('.message-body');
  var ps = new PerfectScrollbar('.notifications');
  var ps = new PerfectScrollbar('.scroll-sidebar');
  var ps = new PerfectScrollbar('.customizer-body');*/

  // ==============================================================
  // To do list
  // ==============================================================
  $(".list-task li label").click(function() {
    $(this).toggleClass("task-done");
  });
  // ==============================================================
  // Collapsable cards
  // ==============================================================
  $('a[data-action="collapse"]').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
    $(this).closest('.card').children('.card-body').collapse('toggle');
  });
  // Toggle fullscreen
  $('a[data-action="expand"]').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
    $(this).closest('.card').toggleClass('card-fullscreen');
  });

  // Close Card
  $('a[data-action="close"]').on('click', function() {
    $(this).closest('.card').removeClass().slideUp('fast');
  });
  // ==============================================================
  // LThis is for mega menu
  // ==============================================================
  $(document).on('click', '.mega-dropdown', function(e) {
    e.stopPropagation()
  });

  // ==============================================================
  // Last month earning
  // ==============================================================
  var sparklineLogin = function() {
    $('.lastmonth').sparkline([6, 10, 9, 11, 9, 10, 12], {
      type: 'bar',
      height: '35',
      barWidth: '4',
      width: '100%',
      resize: true,
      barSpacing: '8',
      barColor: '#2961ff'
    });
  };
  var sparkResize;

  $(window).resize(function(e) {
    clearTimeout(sparkResize);
    sparkResize = setTimeout(sparklineLogin, 500);
  });
  sparklineLogin();

  // ==============================================================
  // This is for the innerleft sidebar
  // ==============================================================
  $(".show-left-part").on('click', function() {
    $('.left-part').toggleClass('show-panel');
    $('.show-left-part').toggleClass('zmdi-menu');
  });

  // For Custom File Input
  $('.custom-file-input').on('change',function(){
    //get the file name
    var fileName = $(this).val();
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').html(fileName);
  });

  $(".promotions__copy-action").on("click", function () {
    const input = document.createElement("input");
    const promo_code = $(this)
      .closest(".promotions-card__promo-code")
      .find("span")[0];

    input.value = promo_code.dataset.promoCode;
    document.body.appendChild(input);

    input.select();
    document.execCommand("Copy");

    promo_code.innerText = "Success Copied!";
    setTimeout(() => {
      promo_code.innerText = input.value;
    }, 1500);

    input.remove();
  });

  $(".copy-link").click(function() {
    $('.copy-section').attr('style', 'display: none;');
    var id_link = $(this).attr('data-id');
    var section = $(`.copy-link-section-${id_link}`);
    var input   = $(`#clipboard-link-${id_link}`);

    section.toggle(200);

    setTimeout(function(){
      input.select();
      document.execCommand("copy");
      alert("Link copied!");
    }, 500);
  });

  $(".show-password").click(function () {
    const icon = $(this).find("i")
    const input = $(this).closest("div").find("input")
    icon.toggleClass("fa-eye-slash fa-eye")


    icon[0].classList.contains("fa-eye")
      ? input.attr("type", "text")
      : input.attr("type", "password")
  });

  $("input.field_number").keypress(function(event) {
    return /\d/.test(String.fromCharCode(event.keyCode));
  });

  // Handle no spaces input promo code
  $("input.nospaces").on("keydown", function (e) {
    return e.which !== 32;
  });

  $("#timepicker1").on("dp.change", function (e) {
    $('#timepicker2').data("DateTimePicker").minDate(e.date);
  });

  $("#timepicker2").on("dp.change", function (e) {
    $('#timepicker1').data("DateTimePicker").maxDate(e.date);
  });

  $('.membership__card').click(function () {
    $(this).find('input').prop('checked', true)
  });

  // Applicant address show/hide state
  $('#validate-country').change(function() {
    if ($(this).val() == 1 || $(this).find('option:selected').text() == 'Malaysia') {
      $('#state select').prop('required', true)
      $('#state').show()
    } else {
      $('#state select').prop('selectedIndex', 0).trigger( "change" );
      $('#state select').removeAttr('required')
      $('#state').hide()
    }
  }).change()

  // btn reject modal achievement submissions
  $('#btn-reject-modal').click(function(){
    $('#modal-reject-reason').modal('show');
  });

  // checkbox btn register on landing page
  $('#checkbox-landing-page').click(function() {
    if ($(this).is(':checked')) {
      $('.register-btn').removeClass('disabled');
      $('#check-terms-of-use').addClass('d-none');
    } else {
      $('.register-btn').addClass('disabled');
      $('#check-terms-of-use').removeClass('d-none')
    }
  })

  $(".down").click(function() {
    $('html, body').animate({
      scrollTop: $(".checkbox-wrapp").offset().top
    }, 1500);
  });
});
